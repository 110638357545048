<ion-app>
  <ion-split-pane  contentId="main">
    <ion-router-outlet id="main"></ion-router-outlet>


      <ion-menu id="app-menu" menuId="app-menu" contentId="main" *ngIf="isLoggedIn && isAuthenticated">
        <ion-header>
          <ion-toolbar color="tertiary">
  
            <ion-menu-toggle  slot="start" mode="ios">
              <ion-button close color="tertiary">
                <fa-icon [icon]="['fal','chevron-left']" slot="start"></fa-icon>
              </ion-button>
            </ion-menu-toggle>
  
            <ion-title>Menu</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content [scrollEvents]="true" >
          <ion-list class="menu-list">
    
            <div *ngIf="!showingNewMembershipForm"> 
              <ion-menu-toggle auto-hide="false">
                <ion-item routerDirection="root" routerLink="app/tabs/home/" lines="none" detail="false" routerLinkActive="selected">
                  <fa-icon [icon]="['fal','house']" slot="start"></fa-icon>
                  <ion-label>{{readySteady}}</ion-label>
                </ion-item> 
              </ion-menu-toggle>
            </div>

            
        <ion-accordion-group id="my_menu">
          <ion-accordion value="my_menu">
            <ion-item slot="header" auto-hide="true"  (click)="ScrollToTop('my_menu')" >
             
              <ion-label>My Menu</ion-label>
            </ion-item>

            <ion-list slot="content">
              <ion-menu-toggle auto-hide="false">


                <ion-menu-toggle auto-hide="false"> 
                  <ion-item routerDirection="root" routerLink="app/tabs/switch-clubs/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('no_club')">
                  <fa-icon [icon]="['fal', 'arrow-up-arrow-down']" slot="start" rotate="90"></fa-icon>
                  <ion-label>My Clubs</ion-label>
                  </ion-item>
                  
                <ion-item routerDirection="root" routerLink="app/tabs/my-details/" lines="none" detail="false" routerLinkActive="selected">
                  <fa-icon [icon]="['fal', 'circle-user']" slot="start"></fa-icon>
                  <ion-label> My Details </ion-label>
                </ion-item>
                
                <div *ngIf="!showingNewMembershipForm" >

                <ion-item routerDirection="root" routerLink="app/tabs/user-settings-comms" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('no_club')">
                  <fa-icon [icon]="['fal', 'tower-cell']" slot="start"></fa-icon>
                  <ion-label>Comms/GDPR</ion-label>
                </ion-item>
      
                
                <ion-item routerDirection="root" routerLink="app/tabs/user-events/" lines="none" detail="false" routerLinkActive="selected">
                  <div class="cmc-icon svg-inline--fa cmc-icon-menu" ></div>            
                  <ion-label> Events </ion-label>
                </ion-item>
               
                <ion-item routerDirection="root" routerLink="app/tabs/parkrun/" lines="none" detail="false" routerLinkActive="selected">
                  <fa-icon [icon]="['fal', 'barcode']" slot="start"></fa-icon>
                  <ion-label> parkrun </ion-label>
                </ion-item>
                <ion-item routerDirection="root" routerLink="app/tabs/pace-calculator/" lines="none" detail="false" routerLinkActive="selected">
                  <fa-icon [icon]="['fal', 'stopwatch']" slot="start"></fa-icon>
                  <ion-label> Pace Calculator </ion-label>
                </ion-item>
                   
                <ion-item routerDirection="root" routerLink="app/tabs/user-settings-switch" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('no_club')">
                  <fa-icon [icon]="['fal', 'repeat']" slot="start"></fa-icon>
                  <ion-label>Switch User</ion-label>
                </ion-item>

                <ion-item routerDirection="root" routerLink="app/tabs/training-history/" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('no_club')">
                  <fa-icon [icon]="['fal', 'rectangle-history']" slot="start"></fa-icon>
                  <ion-label>Training History </ion-label>
                </ion-item>

                <ion-item routerDirection="root" routerLink="app/tabs/virtual-challenges/" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('virtual_challenges')">
                  <fa-icon [icon]="['fal', 'wave-pulse']" slot="start"></fa-icon>
                  <ion-label>Virtual Challenges</ion-label>
                </ion-item>
                </div>

            </ion-menu-toggle>
          
               

              </ion-menu-toggle>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group> 
 

        <div *ngIf="!sideBarMenu.includes('supplier')">
            
     <div id="menu_training" *ngIf="!showingNewMembershipForm && !sideBarMenu.includes('training_section')"> 
    
     <ion-list-header mode="ios" color="tertiary"> Training </ion-list-header>
     
             <ion-menu-toggle auto-hide="false"> 
                  <ion-item routerDirection="root" routerLink="app/tabs/training-sessions/" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'running']" slot="start"></fa-icon>
                    <ion-label> Sessions </ion-label>
                  </ion-item>
                
                 <ion-item routerDirection="root" routerLink="app/tabs/training-leaders/" lines="none" detail="false" routerLinkActive="selected" *ngIf="clubSubscribed">
                          <fa-icon [icon]="['fal', 'person-chalkboard']" slot="start"></fa-icon>
                          <ion-label> Leaders </ion-label>
                 </ion-item>
                 
                        <ion-item routerDirection="root" routerLink="app/tabs/training-plans/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('training_plans')">
                          <fa-icon [icon]="['fal', 'list-check']" slot="start"></fa-icon>
                          <ion-label> Plans </ion-label>
                        </ion-item>
                        <ion-item routerDirection="root" routerLink="app/tabs/training-routes/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('training_routes')">
                          <fa-icon [icon]="['fal', 'route']" slot="start"></fa-icon>
                          <ion-label> Routes </ion-label>
                        </ion-item>

 
                        <ion-item routerDirection="root" routerLink="app/tabs/training-settings/" lines="none" detail="false" routerLinkActive="selected">
                          <fa-icon [icon]="['fal', 'gears']" slot="start"></fa-icon>
                          <ion-label> Settings </ion-label>
                        </ion-item>
                        <ion-item routerDirection="root" routerLink="app/tabs/training-wallet/" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('training_wallet')">
                          <fa-icon [icon]="['fal', 'wallet']" slot="start"></fa-icon>
                          <ion-label> Wallet </ion-label>
                        </ion-item>
                      </ion-menu-toggle>
                </div>

                <div *ngIf="!showingNewMembershipForm" > 

                  <div *ngIf="!sideBarMenu.includes('messaging_section')">
                <ion-list-header mode="ios" color="tertiary"> Messaging </ion-list-header>
                <ion-menu-toggle auto-hide="false"> 
      
                  <ion-item routerDirection="root" routerLink="app/tabs/direct-messages/no" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('dms')">
                    <fa-icon [hidden]="newDM" [icon]="['fal', 'message-arrow-up-right']" slot="start"></fa-icon>
                    <ion-badge  [hidden]="!newDM" slot="start" color="tertiary">New</ion-badge>
                    <ion-label> Direct Messages </ion-label>
                  </ion-item>
      
                  <ion-item routerDirection="root" routerLink="app/tabs/club-email/" lines="none"  detail="false"  routerLinkActive="selected" *ngIf="!sideBarMenu.includes('email_the_club')">
                    <fa-icon [icon]="['fal', 'envelope']" slot="start"></fa-icon>
                    <ion-label> Email The Club </ion-label>
                  </ion-item>
                  
                  <ion-item routerDirection="root" routerLink="app/tabs/group-chats/" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('group_messaging')">
                    <fa-icon [hidden]="newGroupMsg" [icon]="['fal', 'people-line']" slot="start"></fa-icon>
                    <ion-badge  [hidden]="!newGroupMsg" slot="start" color="tertiary">New</ion-badge>
                    <ion-label> Group Chats</ion-label>
                  </ion-item>
      
                  <ion-item routerDirection="root" routerLink="app/tabs/message-board/no" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('message_board')" >
                    <fa-icon  [hidden]="newMessageBoard" [icon]="['fal', 'comments']" slot="start"></fa-icon>
                     <ion-badge  [hidden]="!newMessageBoard" slot="start" color="tertiary">New</ion-badge>
                    <ion-label> Message Board </ion-label>
                  </ion-item>
      
      </ion-menu-toggle>
    </div>

              
                <ion-list-header mode="ios" color="tertiary" *ngIf="!sideBarMenu.includes('no_club')"> Club </ion-list-header>
         
                <ion-menu-toggle auto-hide="false">

                <div *ngIf="!showingNewMembershipForm">

                  <div *ngIf="!sideBarMenu.includes('club_section')">
 

                  <ion-item routerDirection="root" routerLink="app/tabs/club-announcements/no" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_announcements')">
                    <fa-icon [hidden]="newAnnouncement" [icon]="['fal', 'bullhorn']" slot="start"></fa-icon>
                    <ion-badge  [hidden]="!newAnnouncement" slot="start" color="tertiary">New</ion-badge>
                    <ion-label> Announcements </ion-label>
                  </ion-item>
        
                  <ion-item routerDirection="root" routerLink="app/tabs/club-calendar/all" lines="none"  detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('club_calendars')">
                    <fa-icon [icon]="['fal', 'calendar']" slot="start"></fa-icon>
                    <ion-label> Calendars </ion-label>
                  </ion-item>
       
  
                  <ion-item routerDirection="root" routerLink="app/tabs/members-list" lines="none"  detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'users']" slot="start"></fa-icon>
                    <ion-label> Clubmates</ion-label>
                  </ion-item>

             
                  <ion-item routerDirection="root" routerLink="app/tabs/club-records/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_records')">
                    <fa-icon [icon]="['fal', 'award']" slot="start"></fa-icon>
                    <ion-label> Club Records </ion-label>
                  </ion-item>
                  
                  <ion-item routerDirection="root" routerLink="app/tabs/code-of-conduct" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('code_of_conduct')">
                    <fa-icon [icon]="['fal', 'handshake']" slot="start"></fa-icon>
                    <ion-label> Code of Conduct </ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/club-competitions/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_competitions')">
                    <fa-icon [icon]="['fal', 'trophy']" slot="start"></fa-icon>
                    <ion-label> Competitions </ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/club-documents" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_documents')">
                    <fa-icon [icon]="['fal', 'folder-tree']" slot="start"></fa-icon>
                     <ion-label> Documents </ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/event-reports/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('event_reports')">
                    <fa-icon [icon]="['fal', 'book-open']" slot="start"></fa-icon>
                    <ion-label> Event Reports </ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/club-info" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_information')">
                    <fa-icon [icon]="['fal', 'circle-info']" slot="start"></fa-icon>
                    <ion-label> Information </ion-label>
                  </ion-item>
                    
                  <ion-item routerDirection="root" routerLink="app/tabs/committee-list" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('meet_committee')">
                    <fa-icon [icon]="['fal', 'people-roof']" slot="start"></fa-icon>
                     <ion-label> Meet The Committee </ion-label>
                  </ion-item>
                </div> 
              </div>
              <div *ngIf="!sideBarMenu.includes('no_club')">
              <ion-item routerDirection="root" routerLink="app/tabs/membership/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('membership')">
                <fa-icon [icon]="['fal', 'id-card']" slot="start"></fa-icon>
                <ion-label> Membership </ion-label>
              </ion-item>
            </div>
              <div *ngIf="!showingNewMembershipForm">
                <div *ngIf="!sideBarMenu.includes('club_section')">
                
                <ion-item routerDirection="root" routerLink="app/tabs/club-news/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_news')">
                  <fa-icon [icon]="['fal', 'newspaper']" slot="start"></fa-icon>
                  <ion-label> News </ion-label>
                </ion-item>
    
                <ion-item routerDirection="root" routerLink="app/tabs/discounts" lines="none" detail="false"  routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('club_offers')">
                  <ion-badge  [hidden]="!newOffer" slot="start" color="tertiary">New</ion-badge>
                  <fa-icon   [hidden]="newOffer" [icon]="['fal', 'badge-percent']" slot="start"></fa-icon>
                  <ion-label > Offers &amp; Discounts  </ion-label>
                </ion-item>
                <ion-item routerDirection="root" routerLink="app/tabs/photo-albums/" lines="none" detail="false" routerLinkActive="selected"  *ngIf="!sideBarMenu.includes('photo_albums')">
                  <fa-icon [icon]="['fal', 'images']" slot="start"></fa-icon>
                  <ion-label> Photo Albums </ion-label>
                </ion-item>

                <ion-item routerDirection="root" routerLink="app/tabs/club-social" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('social_media')">
                    <fa-icon [icon]="['fal', 'signal-stream']" slot="start"></fa-icon>
                    <ion-label> Social Media </ion-label>
                  </ion-item>
                <ion-item routerDirection="root" routerLink="app/tabs/club-surveys/" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('club_surveys')">
                  <fa-icon [icon]="['fal', 'square-poll-horizontal']" slot="start"></fa-icon>
                  <ion-label> Surveys </ion-label>
                </ion-item>
    
                    </div>
                </div>
                
                </ion-menu-toggle>
            

   


    <div *ngIf="!sideBarMenu.includes('shop_section')"> 
      <ion-list-header mode="ios" color="tertiary"> Club Shop </ion-list-header>
    <ion-menu-toggle auto-hide="false"> 

        <ion-item routerDirection="root" routerLink="app/tabs/club-shop/all_items" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('club_shop')">
          <fa-icon [icon]="['fal', 'shop']" slot="start"></fa-icon>
          <ion-label>Shop</ion-label>
        </ion-item>
        <ion-item routerDirection="root" routerLink="app/tabs/club-special-orders" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('special_orders')">
          <fa-icon [icon]="['fal', 'bags-shopping']" slot="start"></fa-icon>
          <ion-label>Special Orders</ion-label>
        </ion-item>
            <ion-item routerDirection="root" routerLink="app/tabs/club-subscriptions" lines="none" detail="false" routerLinkActive="selected" *ngIf="!sideBarMenu.includes('recurring_billing')">
              <fa-icon [icon]="['fal', 'repeat']" slot="start"></fa-icon>
              <ion-label>Subscriptions</ion-label>
              </ion-item>
            <ion-item routerDirection="root" routerLink="app/tabs/club-shop-orders" lines="none" detail="false" routerLinkActive="selected">
              <fa-icon [icon]="['fal', 'diagram-predecessor']" slot="start"></fa-icon>
              <ion-label>Previous Orders</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </div>

        </div>
      </div>

        <ion-accordion-group id="menu_settings" >
          <ion-accordion value="menu_settings">
            <ion-item slot="header" auto-hide="true"  (click)="ScrollToTop('menu_settings')" >
             
              <ion-label>Settings</ion-label>
            </ion-item>

            <ion-list slot="content">
              <ion-menu-toggle auto-hide="false">


                <ion-item routerDirection="root" routerLink="app/tabs/user-settings" lines="none" detail="false" routerLinkActive="selected">
                  <fa-icon [icon]="['fal', 'gears']" slot="start"></fa-icon>
                  <ion-label>App Settings</ion-label>
                </ion-item>
                <div *ngIf="!showingNewMembershipForm">
                <ion-item routerDirection="root" routerLink="app/tabs/user-settings-connected-apps" lines="none" detail="false" routerLinkActive="selected">
                  <fa-icon [icon]="['fad', 'triangle']" slot="start"></fa-icon>
                  <ion-label>Connected Apps</ion-label>
                </ion-item>
             
                </div>

               

              </ion-menu-toggle>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>

          <ion-accordion-group id="menu_about">
            <ion-accordion value="menu_about">
              <ion-item slot="header" auto-hide="true" (click)="ScrollToTop('menu_about')" >
              
                <ion-label> About  </ion-label>
              </ion-item>
  
              <ion-list slot="content">
                <ion-menu-toggle auto-hide="false">
                  <ion-item routerDirection="root" routerLink="app/tabs/about" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'circle-question']" slot="start"></fa-icon>
                    <ion-label> Help</ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/bug-report/" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'message-pen']" slot="start"></fa-icon>
                    <ion-label>Feedback / Bug Report</ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/about-licences" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fab', 'github']" slot="start"></fa-icon>
                    <ion-label>Licences</ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/about-privacy" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'file-shield']" slot="start"></fa-icon>
                    <ion-label> Privacy Policy</ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/about-terms" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'list-ul']" slot="start"></fa-icon>
                    <ion-label>Terms Of Use</ion-label>
                  </ion-item>
                  <ion-item routerDirection="root" routerLink="app/tabs/about-version" lines="none" detail="false" routerLinkActive="selected">
                    <fa-icon [icon]="['fal', 'code-branch']" slot="start"></fa-icon>
                    <ion-label>Version</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>

     
          <ion-menu-toggle auto-hide="false"> 
          <ion-item button (click)=" logout()"lines="none" detail="false">
            <fa-icon [icon]="['fal', 'right-from-bracket']" slot="start"></fa-icon>
            <ion-label> Logout </ion-label>
          </ion-item>
        </ion-menu-toggle>

          </ion-list>
        </ion-content>
      </ion-menu>
  </ion-split-pane>
</ion-app>
