import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { filter, map, take } from 'rxjs/operators';
 
@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard  {
  constructor(private globalService: GlobalService, private router: Router) { 

     /*this.globalService.isAuthenticated.subscribe( async value => {


      console.log(value)
     if( value === true){
        this.router.navigateByUrl('/app/tabs/home', { replaceUrl:true });
      
      }
     
  }); */
}


  canLoad(): Observable<boolean> {    
    return this.globalService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        console.log('Found previous token, automatic login');

        console.log(isAuthenticated)
        if (isAuthenticated) {
          // Directly open inside area       
       this.router.navigateByUrl('/app', { replaceUrl: true });
          
        } else {          
          // Simply allow access to the login
          return true;
        }
      })
    );
  }

}